.footer {
    font-weight: 200;
    position: absolute;
    top: 95%;
    width: 100%;
    text-align: center;
    color: #e2e2e2;
    transition: filter 2s ease-out; /* Smooth transition for the blur */
}


.footer.blur {
  filter: blur(5px);
}