.slider {
  width: 200px;
  height: 5px;
  -webkit-appearance: none;
  background: #b9b9b9;
  outline: none;
  border-radius: 25px; 
  overflow: hidden;
  margin-bottom: 8.5px;
  cursor: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 8px;
  height: 9px;
  border-radius: 50%;
  background: #d4d4d4;
  border: 1px solid rgb(145, 145, 145);
  box-shadow: -407px 0 0 400px rgb(255, 170, 72);
  cursor: none;
}

.slider-container {
  margin-bottom: 3px;
  padding: 0;
  opacity: 0;
  filter: blur(2px);
  animation: fadeInBlur 1s forwards;
}

.color-input {
  width: 190px;
  background-color: transparent;
  color: rgb(177, 177, 177);
  border: none;
  border-bottom: 1px solid rgb(182, 182, 182);
  margin-left: 3px;
  margin-top: 8px;
  margin-bottom: 5px;
  cursor: none;
}

.color-input:focus {
  outline: none;
  cursor: none;
}

label {
  cursor: none;
  margin-left: 3px;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); filter: blur(2px) }
  100% { opacity: 1; transform: translateY(0); filter: blur(0); }
}

@keyframes fadeOut {
  0% { opacity: 1; transform: translateY(0); filter: blur(0); }
  100% { opacity: 0; transform: translateY(-20px); filter: blur(2px) }
}

@media only screen and (max-height: 668px) {

  .slider-container {
    text-align: center;
  }

  .slider {
    width: 155px
  }

  .color-input {
    width: 155px;
  }

}