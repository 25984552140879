.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: rgb(255, 157, 45);
  box-shadow: 0 0 40px 9px rgb(255, 152, 34);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
  }